<template>
    <div ref="content">
        <div v-if="error">
            <p>Following meta-attributes are missing or empty:</p>
            <ul>
                <li>data-lang</li>
                <li>data-site-slug</li>
                <li>data-disclaimer-type</li>
            </ul>
        </div>
        <div v-if="error404">
            <p>404 | Disclaimer has not been found</p>
        </div>
        <div v-if="disclaimer" v-html="disclaimer"></div>
    </div>
</template>

<script>
import { axios } from "@/helpers"

export default {
    name: 'App',
    data() {
        return {
            error: false,
            error404: false,
            disclaimer: null,
        }
    },
    computed: {
        dev() {
            return process.env.VUE_APP_DEV === "true";
        }
    },
    mounted() {
        let lang, slug, type;

        if(this.dev) {
            lang = 'nl';
            slug = 'alevefeminaxnl';
            type = 'PrivacyAbove';
        } else {
            const el = this.$refs.content.closest('[data-lang]');

            if(! el) {
                this.error = true;

                return;
            }

            lang = el.getAttribute('data-lang');
            slug = el.getAttribute('data-site-slug');
            type = el.getAttribute('data-disclaimer-type');
        }

        if(! lang || ! slug || ! type) {
            this.error = true;

            return;
        }

        axios
            .get('/' + lang + '/site/' + slug + '/disclaimer', { 'params' : { type: type }})
                .then(response => {
                    if(typeof response?.data !== "undefined") {
                        this.disclaimer = response.data;

                        return;
                    }

                    this.error404= true;
                }).catch(()  => {
                    this.error404= true;
                });

    }
}
</script>